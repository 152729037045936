import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationFR from './lang/fr.json';
import translationNL from './lang/nl.json';

i18n
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			fr: {
				translation: translationFR,
			},
			nl: {
				translation: translationNL,
			},
		},
		fallbackLng: 'fr',
		interpolation: {
			escapeValue: false,
		},
	}, (err, t) => {
		if (err) console.error("i18n initialization failed:", err);
		else console.log(i18n.language);
	});